export const patientGTI: any = {
    mainMenu: "Main Navigational Menu Consumer GCSO - Global",
    footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
    banner: "Banner - HTML - Genetic Benefits - DTC - US Global",
    footer: "Retina Global Footer Consumer - US - Global",
    exitPopup: "Retina Canada Exit Popup New - Global",
    hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
    topNav: "Top Navigational Menu Consumer US - Global",
    footerCallout: "Footer Callout GTB - HTML DTC US Global",
    infographic: "GENETIC TESTING BENIFITS- INFOGRAPHIC - Global",
    siteLogo: "Site logos - Global",
    moreAnswersSection: "More answers HTML - GTB Consumer US Global",
    over270Genes: "Over 270 Genes HTML - GTB Consumer US Global",
    earlyTesting: "Early genetic testing HTML - GTB - Consumer US Global",
    prepareToSpeak: "Prepare to speak with your eye specialist - Global",
    GTInconclusive: "Genetic Testing Inconclusive - Global",
    infographicData: "GENETIC TESTING BENIFITS- INFOGRAPHIC - Global",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/genetic-testing-benefit",
        "es": "/es/genetic-testing-benefit"
      }
    },
    backToTopGTM: "Back to top - Importance of Genetic testing",
    footerClassName: "genetic-testing-importance-footer"
  }