import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";
import { font } from "../../../css/common/variables";

export const DisclaimerFooterWrapper = styled.div`
	position: relative;
	top: 14px;
	color: white;
	top: 26px;
	padding-bottom: 50px;

	@media ${device.tablet} {
		top: 42px;
	}

	@media ${device.desktopsignup} {
		top: 42px;
	}


	.disclaimer-para {

		@media ${device.mobileStart} {
			padding: 0 1.3rem;
		}

		@media ${device.tablet} {
			padding: 0;
			margin: auto;
			padding: 0px 2rem;
		}
		@media ${device.ipadLandscapemin} {
			padding-left: 0;
			padding-right: 2.2rem;
			max-width: 1188px;
		}

		@media ${device.desktopsignup} {
			padding: 0 0;
			max-width: 1208px;
		}
		.o-container {
			@media ${device.tablet} {
				margin-left: 0;
			}
		}


		.disclaimer-para-gtb {
      cursor: default;
			z-index: 1000;
			position: relative;
			font-size: 16px;
			line-height: normal;
			margin-bottom: 0;
			font-family: ${font.gotham_book};
			strong {
				font-family: ${font.gotham_bold};
			}
		}
	}
`;

export const UsGeneticTestingBenefitWrapper = styled.div`

	.genetic-testing-benifit-wrapper {
		.footer {
			@media ${device.laptopMedium} {
				top: 0;
			}
			.footercontainer {
				footer {
					> .row {
						&:first-child {
							@media ${device.laptopPros} {
								padding: 25rem 0px 0px;
							}

							@media ${device.tablet} {
								padding: 28rem 0px 0px;
							}

              @media ${device.ipadLandscapemin} {
								padding: 28rem 0px 30px;
							}
						}
					}
				}
			}
		}
	}

  .generic-testing-benefits {
    .testing-benefits-listing {
      .o-container {
        .o-inner {
          h2 {
            &.o-header--h2 {
              font-size: 4.2rem;
              font-weight: 700;
              line-height: 5rem;

							@media ${device.laptop} and ${device.minimumDeskEnd} {
								text-align: center;
							}

							@media ${device.laptopMedium} {
								font-size: 2.5rem;
    						line-height: 3rem;
								text-align: center;
							}
            }
          }
					.more-answer-paragraphs {
						p {
							font-size: 2.2rem;
							line-height: 3.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.2rem;
							}
						}
					}
        }
      }
    }

		.genes-wrapper {
			max-width: 1208px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 2rem;
			margin-bottom: 0;

			@media ${device.mobileMedium} {
				padding: 0 2.7rem;
			}

			.o-container {
				padding-left: 0;
				padding-right: 0;

				@media ${device.laptopMedium} {
					padding-left: 2rem;
					padding-right: 2rem;
				}
				@media ${device.mobileMedium} {
					padding-left: 2.7rem;
					padding-right: 2.7rem;
				}

				@media ${device.laptop} and ${device.minimumDeskEnd} {
					padding-left: 2rem;
					padding-right: 2rem;
				}

				.o-inner-wrapper-gens {
					padding: 0 100px;

					@media ${device.laptopMedium} {
						padding-left: 0;
						padding-right: 0;
					}
					.o-genes-wrap {
						padding: 27px 94px;

						@media ${device.laptopMedium} {
							margin: 0;
							padding: 29px 42px 25px 42px;
						}

						.step-info-box {
							@media ${device.laptopMedium} {
								margin-bottom: 0;
							}
							.info-box {
								.infobox-middle-para {
									font-size: 2.2rem;
									padding-left: 16px;

									@media ${device.laptopMedium} {
										padding-left: 0;
									}
								}
							}
						}
					}

					.step-info-box {
						.info-box {
							.genes-info-box {
								font-family: Gotham, sans-serif;
								padding: 0 0 0 38px;

								@media ${device.laptopMedium} {
									padding-left: 0;
									font-family: Gotham-Book, sans-serif;
								}
							}
						}
					}
				}
			}
		}
		.genetic-cause {
			padding-top: 120px;
    	padding-bottom: 2rem;

			@media ${device.laptopMedium} {
				padding-top: 4.8rem;
    		padding-bottom: 4.4rem;
			}

			.genetic-cause__left-img-secion {
				.CircularProgressbar-text {
					line-height: 3.6rem;
				}
				.inupto,p {
					font-size: 1.7rem;
				}
				small {
					left: 78px;
				}
			}
			.genetic-cause__right-text-secion {
				p {
					font-weight: 500 !important;
    				font-family: "Gotham" !important;
					font-size: 2.7rem !important;
					line-height: 3.8rem !important;

					@media ${device.laptopMedium} {
						font-size: 20px !important;
    					line-height: 24px !important;
						font-family: "Gotham-book" !important;
					}

					strong {
						font-weight: 700 !important;
						@media ${device.minimumDesk} {
							font-family: "Gotham-Bold" !important;
						}
					}
				}
			}
		}
		.testing-list-bg {
			.o-container {
				.bg-color-component {
					.listing-box {
						margin-top: 100px;

						@media ${device.laptopMedium} {
							margin-top: 0;
						}

						.o-header--medium {
							font-size: 4.2rem;
							font-weight: 900;
							line-height: 5rem;
							padding-left: 45px;

							@media ${device.laptop} and ${device.minimumDeskEnd} {
								text-align: center;
								padding-left: 0;
							}

							@media ${device.tablet} and ${device.laptopMedium} {
								font-weight: 900 !important;
							}

							@media ${device.laptopMedium} {
								font-size: 2.5rem;
								line-height: 3rem;
								font-weight: normal;
								text-align: center;
								padding-left: 0;
							}

							strong {
								font-weight: 600;
								@media ${device.mobileMedium} {
									font-weight: 600 !important;
								}
							}
						}

						.genetic-points-paragraphs {
							.listing-section {
								li {
									font-size: 2.2rem;

									@media ${device.laptopMedium} {
										font-size: 1.8rem;
									}
								}
							}
						}
					}
				}
			}
		}

		.community-title-card {
			&.title-card {
				margin-top: 120px;
    		margin-bottom: 0;

				@media ${device.laptopMedium} {
					margin-top: 47px;
				}

				.o-inner {
					.o-header--h2 {
						padding-left: 30px;
						padding-right: 30px;
						padding-bottom: 4.5rem;
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							padding-left: 42px;
							padding-right: 42px;
							padding-bottom: 3.2rem !important;
						}
						@media ${device.mobileMedium} {
							width: 99%;
							margin: auto;
						}
					}
					.footer-block {
						@media ${device.laptopMedium} {
							padding: 0 42px !important;
						}
						.footer-inner-block {
							padding: 40px 28px 128px;

							@media ${device.smalldeskstrt} and ${device.desktopmenu} {
								min-width: unset;
							}

							@media ${device.desktopsignup} {
								min-width: 468px;
							}

							@media ${device.laptopMedium} {
								margin-top: 0;
								min-width: 100%;
								border-radius: 10px;
								height: auto;
								padding: 30px 16px 16px;
							}

							&:nth-child(2) {
								@media ${device.laptopMedium} {
									margin-top: 15px;
								}

								p {
									@media ${device.laptopMedium} {
										max-width: 407px;
										margin: auto;
									}
								}
							}

							p,h2 {
								max-width: unset;
								width: 99%;

								@media ${device.laptopMedium} {
									width: 100%;
									font-size: 2rem;
    							line-height: 2.4rem;
								}
							}
							.o-common-title {
								margin: auto;
								color: #8000BE;
								text-align: center;
								font-weight: 700;

								@media ${device.laptopMedium} {
									padding-bottom: 15px;
									font-size: 2rem;
    							line-height: 1.5;
									padding-left: 0;
									padding-right: 0;
								}
							}
							.media {
								a {
									display: flex;

									@media ${device.laptopMedium} {
										display: block;
										font-size: 1.8rem;
									}
									@media ${device.mobileMedium} {
										line-height: 2.6rem;
									}

									&:after {
										height: 20px;
										width: 33px;
										background-repeat: no-repeat;

										@media ${device.laptopMedium} {
											position: relative;
											left: 5px;
											top: 2px;
										}
									}
								}
							}
						}
					}
				}
			}
			&.o-top-space {
				padding-top: 82px;
				padding-bottom: 88px;

				@media ${device.laptopMedium} {
					padding: 44px 0 48px 0;
				}
			}
		}

		.testing-benefits-image-points-section {
			margin-top: 5rem;

			@media ${device.laptop} {
				margin-top: 0;
			}

			.o-internal--h2 {
				@media ${device.laptopMedium} {
					width: 99%;
				}
			}
			.listing-btm-section {
				.media--image {
					.gatsby-image-wrapper {
						img {
							@media ${device.desktopStart} {
								max-width: 100%;
							}
						}
					}
				}
			}
			.right-listing-points {
				width: 52%;

				@media ${device.laptopMedium} {
					width: 100%;
					margin-top: 0;
				}

				li {
					font-size: 2rem;
					line-height: 3.1rem;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 0px 0 15px 24px;
					}

					&:nth-child(2) {
						width: 99%;
					}
					&:before {
						top: 11px;
						left: 1px;
					}
				}
			}
		}
		.padding-cards-fix {
			.footer-block {
				.footer-inner-block {
					&:nth-child(2) {
						h3 {
							min-height: 75px !important;

							@media ${device.laptopMedium} {
								min-height: unset !important;
							}
						}
					}
					h3 {
						&.o-common-title {
							min-height: unset;

							@media ${device.laptopMedium} {
								font-weight: 600;
								font-size: 20px;
								line-height: 24px;

								@media ${device.mobileMedium} {
									line-height: 24px !important;
								}
							}
						}
					}
					.text-button-wrap {
						p {
							a {
								font-size: 17px;
								@media ${device.laptopMedium} {
									font-size: 18px;
								}
							}
						}
					}
				}

				.footer-block--row {
					.footer-inner-block {
						span {
							a {
								&:after {
									margin-top: 0 !important;

									@media ${device.laptopMedium} {
										display: block;
										margin: auto;
										top: 3px !important;
    								left: -1px;
										margin-top: 5px !important;
									}
								}
							}
						}
					}
				}
			}
		}
  }
`;
export const EarlyTestingBenefit = styled.div`
	.testing-list-bg {
		position: relative;

		&:before {
			height: calc(100% - 210px);
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			width: 100%;
		}

		.o-container {
			.bg-color-component {
				background-color: #F7F7F7;
				box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
				position: relative;

				@media ${device.laptopMedium} {
					padding: 30px;
				}

				&:before {
					content: "";
					background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
					position: absolute;
					top: 0;
					width: 100%;
					height: 16px;
					left: 0;
					right: 0;

					@media ${device.laptopMedium} {
						height: 8px;
					}
				}

				.listing-box {
					margin-top: 30px;

					@media ${device.laptopMedium} {
						margin-top: 0;
					}

					.o-top-space {
						padding: 95px 100px 80px;

						@media ${device.laptopMedium} {
							padding: 5px 0 0;
						}

						h2 {
							margin-bottom: 0;
							p {
								margin: 0;
								font-size: 4.2rem;
								font-weight: 600;
								line-height: 5rem;
								color: #000000;
    							text-align: center;

								@media ${device.laptopMedium} {
									font-size: 2.5rem;
    							line-height: 3rem;
								}
							}
						}

						.genetic-points-paragraphs {
							ul {
								padding: 0;
								margin: 16px auto 0;
								display: table;

								@media ${device.laptopMedium} {
									margin: 12px auto 0;
    								padding: 0 12px;
								}

								li {
									padding: 16px 0 8px 25px;
									display: inline-block;
    								position: relative;
									color: #000000;
									font-size: 2.2rem;
									font-weight: 500;
									line-height: 3.2rem;

									@media ${device.laptopMedium} {
										padding: 6px 0 6px 24px;
										font-size: 1.8rem;
    									line-height: 2.2rem;
									}

									&:before {
										top: 32px;
										content: "";
										position: absolute;
										background-color: #8000BE;
										width: 8px;
										height: 8px;
										transform: translateY(-50%);
										margin: auto;
										left: 0;

										@media ${device.laptopMedium} {
											top: 18px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const HomePageFooterContainer = styled.div`
  .footer-block--row {
    .footer-inner-block {
      p {
        &.o-common-para {
          font-family: "Gotham-Book" !important;
        }
      }

      .text-para-wrap {
        p {
          font-family: "Gotham-Book";
        }
      }
    }
  }
  .home-page {
    .footer {
      @media ${device.mobileMedium} {
        padding: 0 2.7rem;
      }

      .footercontainer {
        @media ${device.ipadLandscape} {
          padding-top: 0;
          padding-bottom: 3rem;
        }
        @media ${device.largeS} and ${device.largeMaxDesktop} {
          padding: 3rem 0 6.8rem;
        }
        footer {
          @media ${device.mobileStart} and ${device.tabletMax} {
            padding: 0 2.5rem;
          }
          @media ${device.mobileMedium} {
            padding: 0 0;
          }
        }
      }
    }
  }
  .footer {
    .left-footer {
      .FooterText1 {
        margin-bottom: 0;
      }
      &.col-lg-8 {
        @media ${device.ipadLandscapemin} and ${device.minideskend} {
          width: 70%;
        }
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          width: 68%;
        }
        @media ${device.smalldeskstrt} and ${device.minimumDeskEnd} {
          width: 70%;
        }
      }
      &.col-lg-4 {
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          flex: 1 0 !important;
        }
      }
    }
    .col-lg-4 {
      @media ${device.smalldeskstrt} and ${device.minimumDeskEnd} {
        width: 30%;
      }
    }
    .col {
      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        flex: 1 0;
      }
    }
    .footercontainer {
      @media ${device.laptopMedium} {
        padding-bottom: 3rem;
      }
      @media ${device.tablet} and ${device.laptopMedium} {
        max-width: 760px;
      }
    }
  }
  .footercontainer {
    footer {
      @media ${device.laptopMedium} {
        max-width: 760px;
      }
      &>.row {
        .show-hide {
          margin-bottom: 0;
        }

        @media ${device.laptopPros} {
          padding: 0 0 3rem;
        }
      }
      @media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
        padding: 0 2rem;
      }
      @media ${device.tabletMax} {
        margin-left: 0;
        margin-right: 0;
        padding: 0 20px;
      }
      @media ${device.mobileMedium} {
        padding: 0 7px;
      }
    }

    @media ${device.laptopMedium} {
      padding-bottom: 30px;
    }
    .col-lg-4 {
      @media ${device.laptopMedium} {
        margin-top: 20px;
      }
    }
    .left-footer {

      .navbar-nav {
        .footer-list {
          .navlinkstyle {
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }

      &>.row {
        .navbar-expand {
          padding-bottom: 0;
          margin-bottom: 0;
          padding-top: 0;
        }
      }

      p {
        margin-bottom: 0;
      }
      .o-column--full {
        padding-top: 1.9rem;
      }
      .o-copyright {
        padding-top: 31px;
        line-height: 2rem;

        @media ${device.laptopMedium} {
          padding-top: 15px;
        }
      }
      .copyright-footer {
        padding-top: 31px;
        line-height: 2rem;

        @media ${device.laptopMedium} {
          padding-top: 15px;
        }

        .do-not-sell {
          color: #00FFD9 !important;
          line-height: 2rem;
        }
      }
      .o-column--full {
        margin-bottom: 0;
      }
      .navbar-nav {
        @media ${device.laptopMedium} {
          display: block;
        }
        li {
          @media ${device.laptopMedium} {
            margin: 20px 0;
            text-align: left;
          }
        }
      }
    }
  }
`;
export const LeftImagePoints = styled.div`
	padding: 120px 0 94px;

	.image-points-section {
		.o-internal--h2 {
			margin: auto;
		}
	}
	@media ${device.laptopMedium} {
		padding: 44px 0 21px;
		width: 100%;
	}
	.listing-btm-section {
		padding: 60px 0 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		@media ${device.laptopMedium} {
			display: block;
			padding: 20px 0 0;
		}

		.media--image {
			 margin: 0 auto;

			@media ${device.laptopMedium} {
				width: 100%;
				margin: 0 auto 17px;
			}

			.gatsby-image-wrapper {
				>div {

					@media ${device.desktopStart} {
						max-width: 805px !important;
					}
				}

				img {
					@media ${device.desktopStart} {
						max-width: 805px;
						width: 805px;
					}

					@media ${device.extraLargeDesktop} {
						max-width: 884px;
						width: 884px;
					}
				}
			}
		}

		.right-listing-points {
			margin: initial;
			align-items: flex-end;
			justify-content: end;
			padding-left: 48px;
			width: 51.6%;
			padding-right: calc((100vw - 1168px) / 2);
			display: table;

			@media ${device.laptopMedium} {
				margin-top: 30px;
				padding-left: 28px;
				padding-right: 28px;
				width: 100%;
				margin: 30px 0 0 0;
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;

				@media ${device.laptopMedium} {
					margin-top: 0px;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}

			&.listing-section {

				@media ${device.laptopMedium} {
					margin-top: 15px;
				}

				li {
					padding: 0 0 24px 26px;
					display: inline-block;
					position: relative;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;

					@media ${device.laptop} and ${device.minimumDeskEnd} {
						padding: 0 0 24px 24px;
					}

					@media ${device.laptopMedium} {
						padding: 0 0 10px 25px;
						margin-top: 0px;
						font-size: 1.8rem;
					line-height: 2.6rem;
					}

					&:before {
						content: "";
						position: absolute;
						background-color: #8000BE;
						width: 8px;
						height: 8px;
						top: 12px;
						margin: auto;
						left: 0;
					}

					sup {
						font-size: 60%;
						line-height: 0;
						vertical-align: baseline;
						position: relative;
						top: -0.5em;
					}
				}
			}
		}
	}
	h2 {
		font-size: 4.2rem;
    font-weight: 700;
    line-height: 5rem;
    color: #000000;
    text-align: center;
		width: 60%;
    margin: 0 auto 60px;

		@media ${device.laptopMedium} {
			width: 100%;
			padding: 0 30px;
			display: flex;
			font-size: 2.5rem;
    	line-height: 3rem;
			margin: 0 auto 20px;
		}
	}

	.listing-btm-section {
    display: flex;
    flex-wrap: wrap;

		@media ${device.laptopMedium} {
			display: block;
		}

		.left-img {
			width: 45%;
			max-width: 696px;

			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				width: 45%;
			}

			@media ${device.desktopStart} {
				width: 45%;
				max-width: 696px;
			}

			@media ${device.laptopL} {
				width: 46%;
			}

			@media ${device.extraLargeDesktop} {
				width: 47.5%;
				max-width: unset;
				margin: unset;
			}

			@media ${device.laptopMedium} {
				width: 100%;
				margin: 0 auto 17px;
			}

			.gatsby-image-wrapper {
				>div {
					@media ${device.extraLargeDesktop} {
						max-width: 884px !important;
					}
				}

				img {
					@media ${device.extraLargeDesktop} {
						max-width: 884px;
						width: 884px;
					}
				}
			}
		}

		ul {
			&.right-description {
				width: 52%;
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding-left: 48px;
				padding-right: calc((100vw - 1168px) / 2);
				display: table;

				@media ${device.laptopMedium} {
					width: 100%;
					padding: 0 28px;
				}

				li {
					padding: 0px 0 24px 26px;
    			line-height: 3.1rem;
					display: inline-block;
    			position: relative;
					color: #000000;
					font-size: 2.2rem;
    			font-weight: 500;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 0px 0 15px 24px;
					}

					&:before {
						top: 15px;
    				left: 1px;
						content: "";
						position: absolute;
						background-color: #8000BE;
						width: 8px;
						height: 8px;
						transform: translateY(-50%);
    				margin: auto;
					}
				}
			}
		}
	}
`;
export const EmerFooterBlock = styled.div`
	.footer-block {

		@media ${device.laptopMedium} {
			margin: 0px 0 -35rem !important;
		}

		@media ${device.tabletMax} {
			margin: 0 0 -42rem !important;
		}

		&.footer-block-bg {
			background-color: transparent;
			padding-top: 0;
		}

		.footer-block--row {
			@media ${device.laptopMedium} {
				.text-button-wrap {
					a {
						margin-left: 0;
						margin-top: 15px;
					}
				}
			}

			.footer-inner-block {
				span.text-para-wrap {
					font-family: Gotham-Book;
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: rgb(0, 0, 0);
					text-align: center;
					margin: 0px;
					display: block;
					padding: 10px 5px 20px;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 10px 0 20px;
					}
				}

				@media ${device.ipadLandscape} {
					padding: 32px 16px 20px !important;
				}

				@media ${device.laptopMedium} {
					&:last-child {
						margin-top: 15px;
					}
				}
				span {
					a {
						&:after {
							margin-top: 7px !important;
						}
						font-size: 2.2rem !important;
						font-weight: 500 !important;
						line-height: 2.2rem !important;

						@media ${device.laptopMedium} {
							font-size: 1.8rem !important;
						}
					}
				}

				&:before {
					background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}

				h3.o-common-title {
					min-height: 45px;
					font-weight: 700;
					font-size: 2.7rem;
					line-height: 3.8rem;
					color: rgb(128, 0, 190);
					text-align: center;
					font-family: Gotham;
					margin-bottom: 0px;

					@media ${device.laptopMedium} {
						font-size: 2rem !important;
					}
				}
			}
		}
	}

	&.gen-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;
			}
		}
	}

	&.imp-gens {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;

				.footer-inner-block {
					&:first-child {
						&:before {
							content: "";
							background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
							width: 100%;
							height: 16px;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 5px 5px 0 0;
						}
					}

					&:last-child {
						&:before {
							background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
						}

						&:last-child {
							img {
								padding: 8px 0px;

								@media ${device.ipadLandscapemin} {
									width: 15%;
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const ContainerWrapper = styled.div`
	@media ${device.minimumDesk} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	@media ${device.largeS} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	&.genes-wrapper {
		margin-bottom: 30px;

		@media ${device.laptopMedium} {
			margin-bottom: 0;
		}

		.o-container {
			.o-inner-wrapper-gens {
				padding: 0 50px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					padding: 0;
				}

				.o-genes-wrap {
					padding: 27px 90px;
					margin: 22px auto 0;
					box-shadow: 0 8px 24px 0 rgb(0 0 0 / 16%);
					display: flex;
					align-items: center;
					background-color: #300064;
					position: relative;
					width: 100%;

					@media ${device.laptopMedium} {
						margin: 0;
						padding: 29px 42px 25px 42px;
						display: block;
					}

					.info-box-2-icon {
						float: left;

						@media ${device.laptopMedium} {
							float: none;
						}

						img {
							left: 95px;
							width: 80px;

							@media ${device.laptopMedium} {
								margin-top: 0;
								width: 44px;
								right: 0;
								text-align: center;
								display: block;
								margin: auto;
							}
						}
					}

					.step-info-box {
						display: flex;
						padding: 0;
						flex-wrap: unset;
						width: auto;
						align-items: unset;

						@media ${device.laptopMedium} {
							display: block;
						}
						.o-container {
							padding: 0;
						}
						.o-inner {
							padding: 0;
						}
						.info-box {
							display: flex;
							@media ${device.laptopMedium} {
								display: block;
							}
							.infobox-middle-para {
								width: min-content;
								line-height: 20px;
								margin: auto 0;
								padding-left: 12px;
								@media ${device.laptopMedium} {
									width: 100%;
									margin: 10px 0 0;
									padding: 0;
									line-height: 2.4rem;
									display: flex;
									justify-content: center;
									align-items: center;
								}
								span {
									padding-top: 3px;
									font-size: 4.2rem;
									line-height: 40px;
									display: inline-block;
									@media ${device.laptopMedium} {
										font-size: 3.2rem;
										line-height: 3.5rem;
										padding: 0 6px;
									}
								}
							}
							.genes-info-box {
								text-align: left;
								margin: 0;
								padding: 0 0 0 32px;
								font-size: 2.2rem;
								line-height: 3.2rem;
								@media ${device.laptopMedium} {
									text-align: center;
									padding: 0;
									margin-top: 10px;
									font-size: 2rem;
									line-height: 2.4rem;
								}
							}
						}
						.over-genes-wrapper {
							color: #fff;
							font-weight: 500;
							text-align: center;
    						line-height: 20px;
							font-size: 2.2rem;
							margin: 7px 0 0;
							width: auto;

							@media ${device.laptopMedium} {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								line-height: 2.4rem;
								margin-top: 10px;
								font-size: 2rem;
							}

							span {
								line-height: 40px;
								padding-top: 3px;
								color: #00FFD9;
   								display: block;
								font-size: 4.2rem;

								@media ${device.laptopMedium} {
									font-size: 3.2rem;
									padding: 0 6px;
								}
							}
						}

						.over-genes-para {
							padding: 0 0 0 32px;
							font-size: 2.2rem;
							line-height: 3.2rem;
							font-family: "Gotham-Book";
							text-align: left;
    						color: #fff;
							font-weight: 500;
							margin: 0;

							@media ${device.laptopMedium} {
								font-size: 2rem;
    							line-height: 2.4rem;
								padding: 0;
								text-align: center;
    							margin-top: 10px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
`;
export const TestorRetestPatientSectionWrapper = styled.div`
	.o-container {
		.info-box-2 {
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 40px 100px;
			position: relative;
			flex-wrap: wrap;
			width: 100%;

			span {
				color:#00FFD9;
			}

			@media ${device.laptopMedium} {
				padding: 20px 10px;
				margin: 0 0 18px 0;
				justify-content: center;
			}

			p {
				width: 80%;
				margin: auto;
				text-align: center;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #fff;

				@media ${device.laptopMedium} {
					width: 100%;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
		}
	}
`;
export const MoreAnswerTestingBenefit = styled.div`
	.testing-benefits-listing {
		padding: 80px 0 120px;

		@media ${device.laptopMedium} {
			padding: 27px 0 48px;
		}

		.o-container {
			.o-inner {
				padding: 0 100px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				.o-header {
					margin-bottom: 0;
					p {
						color: #000000;
						font-weight: 700;
    					text-align: center;
						font-size: 4.2rem;
    					line-height: 5rem;
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							font-size: 2.5rem;
							line-height: 3rem;
						}

						strong {
							font-weight: 700;
						}
					}
				}

				.more-answer-paragraphs {
					&:nth-child(2) {
						margin: 0 80px;

						@media ${device.laptopMedium} {
							margin: 0;
						}
					}
					&:nth-child(3) {
						padding: 0;

						@media ${device.laptopMedium} {
							padding: 0;
						}
					}

					p {
						color: #000000;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						text-align: center;
    					padding: 40px 0;
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							padding: 20px 0 24px 0;
							font-size: 1.8rem;
							line-height: 2.2rem;
						}
						span {
							color: #8000BE;
							font-weight: 700;
						}
						strong {
							color: #8000BE;
    						font-weight: 700;
							font-size: 2.2rem;
    						line-height: 3.2rem;
							font-family: 'Gotham';

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
    							line-height: 2.2rem;
							}
						}

						br {
							display: none;
						}
					}

					ul {
						display: inline-block;
    					padding: 0;
						margin: 0;
						list-style: none;

						li {
							color: #000000;
							padding: 12px 0;
							line-height: 3.2rem;
							font-family: "Gotham";
							font-weight: normal;
							font-size: 2.7rem;
							display: -webkit-inline-box;

							@media ${device.laptopMedium} {
								line-height: 2.4rem;
								position: relative;
								padding: 8px 0 8px 40px;
								color: #000000;
								font-size: 2rem;
								text-indent: unset;
							}

							span {
								display: inline-block;
								background-color: #8000BE;
								color: #ffffff;
								border-radius: 50%;
								text-align: center;
								width: 38px;
								height: 38px;
								line-height: 38px;
								font-size: 2.2rem;
								margin: 0 4px 0 0;
								text-indent: 0;

								@media ${device.laptopMedium} {
									width: 32px;
									height: 32px;
									line-height: 32px;
									font-size: 2rem;
									position: absolute;
									left: 0;
									margin-right: 0;
									margin-top: -3px;
									text-indent: unset;
								}
							}
						}
					}
				}
			}
		}
	}
`;
