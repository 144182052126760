import React from 'react';
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby';
import InfoGraphicProgressProvider from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/ReactCircularProgressbar';
import { PageWrapper, GenTestBenefitWrapperMain } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import {
  FooterCalloutBlock,
  HomePageFooterContainer,
  UsHeaderContainer
} from '../../../css/common/style'
import {
  EarlyTestingBenefit,
  UsGeneticTestingBenefitWrapper,
  LeftImagePoints,
  EmerFooterBlock,
  ContainerWrapper,
  TestorRetestPatientSectionWrapper,
  MoreAnswerTestingBenefit,
} from './styles'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import retinaConfig from '../../../utils/retinaConfigs'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, infographicDataBuilder } from '../../../utils'
import { patientGTI } from './constants'

/** Renders genetic-testing-importance-page template
 *
 * @param props props
 * @returns
 */

const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;

  const pgContextsGTB = props.pageContext
  const htmlStaticFilesGTB = {
    nonSVGImages: pgContextsGTB.nonSVGImages,
    svgMediaImages: pgContextsGTB.svgMediaImages,
    allMediaDocument: pgContextsGTB.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientGTI.mainMenu, siteFooterMenu: patientGTI.patFooterMenu })

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientGTI.footer
  const exitPopupTitle = patientGTI.exitPopup
  const hcpSwitcherTitle = patientGTI.hcpSwitcher
  const topNavTitle = patientGTI.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: patientGTI.infographic });

  //Rendering Prepare to speak with your eye specialist to genetic testing benefit page
  const eyeSpHTMLData = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.prepareToSpeak })
  const htmlData = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.GTInconclusive })
  const earlyTestHTML = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.earlyTesting })
  const over270GenesHTML = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.over270Genes })
  const moreAnswerTestingBenefitHTML = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.moreAnswersSection })
  const siteLogos = deriveSiteLogo({ blocks: blocks, title: patientGTI.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.banner })
  // Accessing Footer Navigational callout HTML data
  const footerCalloutGTBHTML = fullHTMLDataBuilder({ blocks: blocks, title: patientGTI.footerCallout })

  return (
    <>
      <UsHeaderContainer>
        <UsGeneticTestingBenefitWrapper>
          <HomePageFooterContainer>
            <GenTestBenefitWrapperMain className='genetic-testing-wrapper-main genetic-testing-benifit-wrapper'>
              {props.pageContext !== null && props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
              <Layout
                title={"siteTitle"}
                location={props.location}
                data={mainMenu}
                mdata={footerMenu}
                footerData={footerText}
                audience={audience}
                exitPopData={exitData}
                hcplinks={hcpSwitcher}
                topNavigation={topNavs}
                staticMediaFiles={htmlStaticFilesGTB}
                siteLogos={siteLogos}
                backToTopContent={retinaConfig.backToTopContent}
                gtmBackToTopLabel={patientGTI.backToTopGTM}
                modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
                modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
                hcpValidate={retinaConfig.hcpValidate}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
                preIndexUrl={retinaConfig.preIndexUrl}
                hcpPrefix={retinaConfig.hcpPrefix}
                siteHomeUrl={retinaConfig.siteHomeUrl}
                footerClassName={patientGTI.footerClassName}
                speechLangcode={retinaConfig.langConfig.speechLangcode}
                cpraFooter={true}
                cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
                cpraOT={true}
                cpraOTEnv={process.env.OT_ENV}
                cpraOTuuid={process.env.OT_UUID}
                languageConfig={{ ...retinaConfig.langConfig, ...patientGTI.redirectLangUrl }}
                {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
              >
                <PageWrapper className='pagewrapper generic-testing-benefits'>
                  <MobileBanner className="mobile-banner clinical-trials-banner">
                    <HTMLRenderer data={htmlStaticFilesGTB} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                  </MobileBanner>
                  {/*Retina Canada Patients - "Importance of Genetic Testing" page -Speak with your eye specialist Component- EN*/}
                  <MoreAnswerTestingBenefit>
                    <HTMLRenderer html={moreAnswerTestingBenefitHTML} data={htmlStaticFilesGTB} tagName='div' className='fullwidth-listing o-top-space o-bottom-space testing-benefits-listing' />
                  </MoreAnswerTestingBenefit>
                  <ContainerWrapper className='container genes-wrapper'>
                    <TestorRetestPatientSectionWrapper>
                      <HTMLRenderer html={over270GenesHTML} data={htmlStaticFilesGTB} tagName='div' className='o-container' simpleImage={true} />
                    </TestorRetestPatientSectionWrapper>
                  </ContainerWrapper>
                  {/*Retina Canada Patient - "Importance of Genetic Testing" page -More Answers & Infographic Component-EN*/}
                  <InfoGraphicProgressProvider per={per} text1={text1} text2={text2} />
                  <div className='early-testing-benefita-container'>
                    <EarlyTestingBenefit>
                      <HTMLRenderer html={earlyTestHTML} data={htmlStaticFilesGTB} className='o-section--cover-bg testing-list-bg' tagName='div' />
                    </EarlyTestingBenefit>
                  </div>
                  <HTMLRenderer data={htmlStaticFilesGTB} html={eyeSpHTMLData} />
                  <LeftImagePoints><HTMLRenderer data={htmlStaticFilesGTB} html={htmlData} /></LeftImagePoints>
                  {/* Retina Canada Patients - Importance of Genetic Testing page -Footer callout Component - EN */}
                  <EmerFooterBlock className='genes-wrapper imp-gens'>
                    <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                      <HTMLRenderer html={footerCalloutGTBHTML} data={htmlStaticFilesGTB} tagName='div' className='footer-block footer-block-bg' />
                    </FooterCalloutBlock>
                  </EmerFooterBlock>
                </PageWrapper>
              </Layout>
            </GenTestBenefitWrapperMain>
          </HomePageFooterContainer>
        </UsGeneticTestingBenefitWrapper>
      </UsHeaderContainer>
    </>
  )
};

export default GeneticTestingBenefitTemplate;

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... GTIPageQuery
      }
    }
  }
`